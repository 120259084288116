// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H2 from "../../../../../styleguide/components/Heading/H2.res.js";
import * as Url from "../../../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as IconOk from "../../../../../styleguide/icons/IconOk.res.js";
import * as IconPin from "../../../../../styleguide/icons/IconPin.res.js";
import * as IconWeb from "../../../../../styleguide/icons/IconWeb.res.js";
import * as Picture from "../../../../../styleguide/forms/Picture/Picture.res.js";
import * as Popover from "../../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as $$Location from "../../../../../models/Location.res.js";
import * as IconPhone from "../../../../../styleguide/icons/IconPhone.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconSelect from "../../../../../styleguide/icons/IconSelect.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconDownload from "../../../../../styleguide/icons/IconDownload.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as Routes_Provider from "../../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_ProviderBrochure from "../../../../../api/provider-brochures/Api_ProviderBrochure.res.js";
import * as SignInModalContainer from "../../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as ProviderShowSidebarStat from "./components/ProviderShowSidebarStat.res.js";
import * as ProviderShowSidebarScss from "./ProviderShowSidebar.scss";

var css = ProviderShowSidebarScss;

var initialState = {
  showSignIn: false
};

function reducer(state, action) {
  return {
          showSignIn: !state.showSignIn
        };
}

function ProviderShowSidebar(props) {
  var userLoginStatus = props.userLoginStatus;
  var providerBrochures = props.providerBrochures;
  var images = props.images;
  var provider = props.provider;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var notifyAndDownload = function (providerBrochure) {
    $$Promise.wait(Api_ProviderBrochure.notifyDownload(providerBrochure.id), (function (x) {
            if (x.TAG === "Ok") {
              return Url.visitBlank(providerBrochure.privateDownload);
            } else {
              return SentryLogger.error1({
                          rootModule: "ProviderShowSidebar",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "ProviderShowSidebar.make"
                        }, "ProviderShowSidebar" + "::ProviderBrochure::notifyDownload::Error", [
                          "Error",
                          x._0
                        ]);
            }
          }));
  };
  var tmp;
  if (images.length !== 0) {
    var x = images.length;
    tmp = JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx(Picture.make, {
                  src: Belt_Option.getExn(Belt_Option.map(Belt_Array.get(images, 0), (function (x) {
                              return x.url;
                            }))),
                  large: [
                    320,
                    240
                  ],
                  className: css.providerImage,
                  fallbackSrc: $$Location.imageNotFoundUrl
                }),
            JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsx("div", {
                        children: "View images",
                        className: css.sidebarImageOverlayText
                      }),
                  className: css.sidebarImageOverlay
                }),
            JsxRuntime.jsx("div", {
                  children: x === 1 ? "View 1 Image" : "View " + String(images.length) + " Images",
                  className: css.imageCount
                })
          ],
          className: css.providerImages,
          onClick: props.onClick
        });
  } else {
    tmp = null;
  }
  var x$1 = providerBrochures.length;
  var tmp$1;
  if (x$1 === 1) {
    var brochure = Belt_Option.getExn(Belt_Array.get(providerBrochures, 0));
    tmp$1 = JsxRuntime.jsx("div", {
          children: JsxRuntime.jsx(Button.make, {
                size: "SM",
                color: "Teal",
                expanded: true,
                disabled: brochure.pdfFile === "",
                visuallyDisabled: brochure.pdfFile.length === 0,
                onClick: (function (param) {
                    if (userLoginStatus === "LoggedIn") {
                      return notifyAndDownload(brochure);
                    } else {
                      return dispatch("ToggleSignInModal");
                    }
                  }),
                children: "Download Brochure"
              }),
          className: css.downloadBrochuresContainer
        });
  } else {
    tmp$1 = x$1 > 1 ? JsxRuntime.jsx("div", {
            children: JsxRuntime.jsxs(Popover.make, {
                  id: "brochures",
                  children: [
                    JsxRuntime.jsx(Popover.Trigger.make, {
                          children: JsxRuntime.jsx(Button.make, {
                                size: "SM",
                                color: "Teal",
                                expanded: true,
                                className: css.ctaButton,
                                children: "Download Brochures",
                                iconSuffixComponent: Caml_option.some(JsxRuntime.jsx(IconSelect.make, {
                                          size: "SM",
                                          color: "White"
                                        }))
                              })
                        }),
                    JsxRuntime.jsx(Popover.Body.make, {
                          position: {
                            TAG: "Below",
                            _0: "RightEdge"
                          },
                          className: css.popoverBody,
                          children: Belt_Array.map(providerBrochures, (function (brochure) {
                                  return JsxRuntime.jsxs("a", {
                                              children: [
                                                JsxRuntime.jsx("span", {
                                                      children: brochure.title,
                                                      className: css.brochureLink
                                                    }),
                                                JsxRuntime.jsx(IconDownload.make, {
                                                      size: "SM",
                                                      color: "DarkGray",
                                                      className: css.popoverBodyLinkIcon
                                                    })
                                              ],
                                              className: css.popoverBodyLink,
                                              href: "#",
                                              onClick: (function (param) {
                                                  if (userLoginStatus === "LoggedIn") {
                                                    return notifyAndDownload(brochure);
                                                  } else {
                                                    return dispatch("ToggleSignInModal");
                                                  }
                                                })
                                            });
                                }))
                        })
                  ]
                }),
            className: css.downloadBrochuresContainer
          }) : null;
  }
  var x$2 = provider.numberEmployees;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(Picture.make, {
                                            src: provider.logo,
                                            large: [
                                              140,
                                              100
                                            ],
                                            crop: "Fit"
                                          }),
                                      className: css.logo
                                    }),
                                provider.agreement && provider.showAgreementCheck ? JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(IconOk.make, {
                                              size: "XXS",
                                              color: "White"
                                            }),
                                        className: css.agreementCheck
                                      }) : null
                              ],
                              className: css.sidebarImage,
                              id: "providerSidebarImage"
                            }),
                        JsxRuntime.jsxs(H2.make, {
                              className: css.sidebarTitle,
                              children: [
                                JsxRuntime.jsx("span", {
                                      children: provider.name,
                                      className: css.sidebarSubtitle
                                    }),
                                "Provider Overview"
                              ]
                            }),
                        tmp,
                        JsxRuntime.jsxs("div", {
                              children: [
                                provider.hqFullAddress === "" ? null : JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx("span", {
                                                children: JsxRuntime.jsx(IconPin.make, {
                                                      size: "SM",
                                                      color: "DarkerGray"
                                                    }),
                                                className: css.sidebarIcon
                                              }),
                                          JsxRuntime.jsx("span", {
                                                children: provider.hqFullAddress,
                                                className: css.sidebarAddress,
                                                id: "sidebarAddress"
                                              })
                                        ],
                                        className: Cx.cx([
                                              css.sidebarData,
                                              css.sidebarAddress
                                            ]),
                                        id: "providersSidebarContactAddress"
                                      }),
                                provider.displayPhoneNumber === "" ? null : JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx("span", {
                                                children: JsxRuntime.jsx(IconPhone.make, {
                                                      size: "SM",
                                                      color: "DarkerGray"
                                                    }),
                                                className: css.sidebarIcon,
                                                id: "sidebarPhone"
                                              }),
                                          JsxRuntime.jsx("span", {
                                                children: "+1-" + provider.displayPhoneNumber,
                                                className: css.sidebarPhone,
                                                id: "sidebarPhone"
                                              })
                                        ],
                                        className: Cx.cx([
                                              css.sidebarData,
                                              css.sidebarPhone
                                            ]),
                                        id: "providersSidebarContactPhone"
                                      }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: JsxRuntime.jsx(IconWeb.make, {
                                                    size: "SM",
                                                    color: "DarkerGray"
                                                  }),
                                              className: css.sidebarIcon,
                                              id: "sidebarProvider"
                                            }),
                                        JsxRuntime.jsx("span", {
                                              children: JsxRuntime.jsx(A.make, {
                                                    id: "sidebarProviderLink",
                                                    href: Routes_Provider.show(provider.slug),
                                                    className: css.sidebarProviderLink,
                                                    children: "View " + provider.name
                                                  }),
                                              className: css.sidebarProvider,
                                              id: "sidebarProvider"
                                            })
                                      ],
                                      className: Cx.cx([
                                            css.sidebarData,
                                            css.sidebarProvider
                                          ]),
                                      id: "providersSidebarContactProvider"
                                    }),
                                tmp$1
                              ],
                              className: css.sidebarContact,
                              id: "providersSidebarContact"
                            })
                      ],
                      className: css.sidebarSummary,
                      id: "providerSidebarSummary"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        provider.locations.totalLocations > 0 ? JsxRuntime.jsx(ProviderShowSidebarStat.make, {
                                slug: provider.slug,
                                icon: "TotalDataCenters",
                                stat: String(provider.locations.totalLocations)
                              }) : null,
                        provider.yearFounded === "" ? null : JsxRuntime.jsx(ProviderShowSidebarStat.make, {
                                icon: "YearFounded",
                                stat: provider.yearFounded
                              }),
                        x$2 === "0" || x$2 === "0.0" || x$2 === "" ? null : JsxRuntime.jsx(ProviderShowSidebarStat.make, {
                                icon: "NumberEmployees",
                                stat: x$2
                              })
                      ],
                      className: css.sidebarStats,
                      id: "providersSidebarStats"
                    }),
                match[0].showSignIn ? JsxRuntime.jsx(SignInModalContainer.make, {
                        close: (function () {
                            dispatch("ToggleSignInModal");
                          }),
                        callback: (function () {
                            
                          }),
                        setUserData: props.setUserData,
                        subTitle: "Sign in to download a Location Brochure."
                      }) : null
              ],
              className: css.providerSidebar,
              id: "providerSidebar"
            });
}

var make = ProviderShowSidebar;

export {
  css ,
  initialState ,
  reducer ,
  make ,
}
/* css Not a pure module */
