// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H3 from "../../../../../../../styleguide/components/Heading/H3.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderShowContentFeaturesScss from "./ProviderShowContentFeatures.scss";

var css = ProviderShowContentFeaturesScss;

function ProviderShowContentFeatures(props) {
  var features = props.features;
  if (features.length !== 0) {
    return JsxRuntime.jsx(JsxRuntime.Fragment, {
                children: Caml_option.some(Belt_Array.mapWithIndex(features, (function (index, feature) {
                            return JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx(H3.make, {
                                                className: css.featureName,
                                                children: feature.name
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: feature.description,
                                                className: css.featureDescription
                                              })
                                        ],
                                        className: css.providerFeature,
                                        id: "providerFeature"
                                      }, String(index));
                          })))
              });
  } else {
    return null;
  }
}

var make = ProviderShowContentFeatures;

export {
  css ,
  make ,
}
/* css Not a pure module */
