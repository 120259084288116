// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../../../../styleguide/components/Link/A.res.js";
import * as H5 from "../../../../../../../../styleguide/components/Heading/H5.res.js";
import * as Url from "../../../../../../../../utils/Url.res.js";
import * as React from "react";
import * as $$Promise from "../../../../../../../../bindings/Promise.res.js";
import * as IconDownload from "../../../../../../../../styleguide/icons/IconDownload.res.js";
import * as SentryLogger from "../../../../../../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_ProviderBrochure from "../../../../../../../../api/provider-brochures/Api_ProviderBrochure.res.js";
import * as SignInModalContainer from "../../../../../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as ProviderShowContentBrochureScss from "./ProviderShowContentBrochure.scss";

var css = ProviderShowContentBrochureScss;

var initialState = {
  showSignIn: false
};

function reducer(state, action) {
  return {
          showSignIn: !state.showSignIn
        };
}

function ProviderShowContentBrochure(props) {
  var userLoginStatus = props.userLoginStatus;
  var providerBrochure = props.providerBrochure;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(A.make, {
                      href: "#",
                      onClick: (function (param) {
                          if (userLoginStatus === "LoggedIn") {
                            return $$Promise.wait(Api_ProviderBrochure.notifyDownload(providerBrochure.id), (function (x) {
                                          if (x.TAG === "Ok") {
                                            return Url.visitBlank(providerBrochure.privateDownload);
                                          } else {
                                            return SentryLogger.error1({
                                                        rootModule: "ProviderShowContentBrochure",
                                                        subModulePath: /* [] */0,
                                                        value: "make",
                                                        fullPath: "ProviderShowContentBrochure.make"
                                                      }, "ProviderShowContentBrochure" + "::ProviderBrochure::notifyDownload::Error", [
                                                        "Error",
                                                        x._0
                                                      ]);
                                          }
                                        }));
                          } else {
                            return dispatch("ToggleSignInModal");
                          }
                        }),
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx("img", {
                                          className: css.image,
                                          src: providerBrochure.image
                                        }),
                                    className: css.imageContainer
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(H5.make, {
                                            className: css.title,
                                            children: providerBrochure.title
                                          }),
                                      JsxRuntime.jsx("p", {
                                            children: providerBrochure.subtitle,
                                            className: css.subtitle
                                          })
                                    ],
                                    className: css.titleContainer
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(IconDownload.make, {
                                          size: "SM",
                                          color: "DarkGray",
                                          className: css.download
                                        }),
                                    className: css.downloadContainer
                                  })
                            ],
                            className: css.brochure
                          })
                    }),
                match[0].showSignIn ? JsxRuntime.jsx(SignInModalContainer.make, {
                        close: (function () {
                            dispatch("ToggleSignInModal");
                          }),
                        callback: (function () {
                            
                          }),
                        setUserData: props.setUserData,
                        subTitle: "Sign in to download a Provider Brochure."
                      }) : null
              ]
            });
}

var make = ProviderShowContentBrochure;

export {
  css ,
  initialState ,
  reducer ,
  make ,
}
/* css Not a pure module */
