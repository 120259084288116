// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../../../styleguide/components/Heading/H2.res.js";
import * as H3 from "../../../../../../../styleguide/components/Heading/H3.res.js";
import * as IconOk from "../../../../../../../styleguide/icons/IconOk.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderShowContentDirectoryServicesScss from "./ProviderShowContentDirectoryServices.scss";

var css = ProviderShowContentDirectoryServicesScss;

function ProviderShowContentDirectoryServices(props) {
  var provider = props.provider;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H2.make, {
                      id: "directoryServicesTitle",
                      className: css.directoryServicesTitle,
                      children: "Data Center Services: " + provider.name
                    }),
                JsxRuntime.jsx("div", {
                      children: Belt_Array.mapWithIndex(provider.directoryServices, (function (index, directory) {
                              if (directory.directoryServices.length !== 0) {
                                return JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(H3.make, {
                                                    id: "directoryTitle",
                                                    className: css.directoryTitle,
                                                    children: directory.directoryTitle
                                                  }),
                                              JsxRuntime.jsx("div", {
                                                    children: Belt_Array.mapWithIndex(directory.directoryServices, (function (sIndex, service) {
                                                            return JsxRuntime.jsxs("div", {
                                                                        children: [
                                                                          JsxRuntime.jsx(IconOk.make, {
                                                                                size: "SM",
                                                                                color: "Teal"
                                                                              }),
                                                                          JsxRuntime.jsx("div", {
                                                                                children: service.title,
                                                                                className: css.serviceLabelContainer
                                                                              })
                                                                        ],
                                                                        className: css.directoryService,
                                                                        id: service.title
                                                                      }, "directoryService-" + String(sIndex));
                                                          })),
                                                    className: css.directoryServices
                                                  })
                                            ],
                                            className: css.directory
                                          }, "directory-" + String(index));
                              } else {
                                return null;
                              }
                            })),
                      className: css.directories
                    })
              ],
              className: css.directoryServices,
              id: "directoryServices"
            });
}

var make = ProviderShowContentDirectoryServices;

export {
  css ,
  make ,
}
/* css Not a pure module */
