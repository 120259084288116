// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../../../styleguide/components/Heading/H2.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderShowContentFeatures from "../features/ProviderShowContentFeatures.res.js";
import * as ProviderShowMainContentScss from "../../ProviderShowMainContent.scss";

var css = ProviderShowMainContentScss;

function ProviderShowContentDetails(props) {
  var provider = props.provider;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H2.make, {
                              id: "contentDetailsTitle",
                              className: css.contentDetailsTitle,
                              children: "About " + provider.name
                            }),
                        JsxRuntime.jsx("div", {
                              className: css.contentDescription,
                              id: "contentDescription",
                              dangerouslySetInnerHTML: {
                                __html: provider.description
                              }
                            })
                      ],
                      className: css.contentDescriptionContainer
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(ProviderShowContentFeatures.make, {
                            features: provider.features
                          }),
                      className: css.contentFeatures,
                      id: "contentFeatures"
                    })
              ],
              className: css.contentDetails,
              id: "contentDetails"
            });
}

var make = ProviderShowContentDetails;

export {
  css ,
  make ,
}
/* css Not a pure module */
