// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../../styleguide/components/Heading/H1.res.js";
import * as Hooks from "../../../../../libs/Hooks.res.js";
import * as Breadcrumb from "../../../../../styleguide/components/Breadcrumb/Breadcrumb.res.js";
import * as Routes_Provider from "../../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderShowContentTabs from "./components/tabs/ProviderShowContentTabs.res.js";
import * as ProviderShowContentDetails from "./components/details/ProviderShowContentDetails.res.js";
import * as ProviderShowContentBrochures from "./components/brochures/ProviderShowContentBrochures.res.js";
import * as ProviderShowMainContentScss from "./ProviderShowMainContent.scss";
import * as ProviderShowContentCertifications from "./components/certifications/ProviderShowContentCertifications.res.js";
import * as ProviderShowContentDirectoryServices from "./components/directory-services/ProviderShowContentDirectoryServices.res.js";

var css = ProviderShowMainContentScss;

var initialState = {
  currentTab: "Details"
};

var tabs = [
  {
    name: "Details",
    tab: "Details"
  },
  {
    name: "Services",
    tab: "Services"
  },
  {
    name: "Certifications",
    tab: "Certifications"
  },
  {
    name: "Brochures",
    tab: "Brochures"
  }
];

function ProviderShowMainContent(props) {
  var isMobile = props.isMobile;
  var providerRole = props.providerRole;
  var userRole = props.userRole;
  var provider = props.provider;
  var match = Hooks.useReducer(initialState, (function (_state, action) {
          return {
                  TAG: "Update",
                  _0: {
                    currentTab: action._0
                  }
                };
        }));
  var dispatch = match[1];
  var state = match[0];
  var tmp;
  switch (userRole) {
    case "Admin" :
        tmp = JsxRuntime.jsx(Breadcrumb.make, {
              breadcrumbLinks: [
                {
                  linkName: "Home",
                  linkPath: "/"
                },
                {
                  linkName: "All Providers",
                  linkPath: Routes_Provider.index
                },
                {
                  linkName: provider.name,
                  linkPath: Routes_Provider.show(provider.slug)
                },
                {
                  linkName: "Edit",
                  linkPath: Routes_Provider.Dashboard.edit(provider.slug)
                }
              ],
              wrapperContainerClassName: css.breadcrumbs
            });
        break;
    case "Provider" :
        var exit = 0;
        switch (providerRole) {
          case "Owner" :
          case "Manager" :
              exit = 1;
              break;
          case "Member" :
          case "Other" :
              tmp = null;
              break;
          
        }
        if (exit === 1) {
          tmp = JsxRuntime.jsx(Breadcrumb.make, {
                breadcrumbLinks: [
                  {
                    linkName: "Home",
                    linkPath: "/"
                  },
                  {
                    linkName: "All Providers",
                    linkPath: Routes_Provider.index
                  },
                  {
                    linkName: provider.name,
                    linkPath: Routes_Provider.show(provider.slug)
                  },
                  {
                    linkName: "Edit",
                    linkPath: Routes_Provider.Dashboard.edit(provider.slug)
                  }
                ],
                wrapperContainerClassName: css.breadcrumbs
              });
        }
        break;
    default:
      tmp = JsxRuntime.jsx(Breadcrumb.make, {
            breadcrumbLinks: [
              {
                linkName: "Home",
                linkPath: "/"
              },
              {
                linkName: "All Providers",
                linkPath: Routes_Provider.index
              },
              {
                linkName: provider.name,
                linkPath: Routes_Provider.show(provider.slug)
              }
            ],
            wrapperContainerClassName: css.breadcrumbs
          });
  }
  var match$1 = state.currentTab;
  var tmp$1;
  var exit$1 = 0;
  switch (match$1) {
    case "Details" :
        tmp$1 = JsxRuntime.jsx(ProviderShowContentDetails.make, {
              provider: provider
            });
        break;
    case "Services" :
        tmp$1 = JsxRuntime.jsx(ProviderShowContentDirectoryServices.make, {
              provider: provider
            });
        break;
    case "Brochures" :
        tmp$1 = JsxRuntime.jsx(ProviderShowContentBrochures.make, {
              providerBrochures: props.providerBrochures,
              provider: provider,
              userRole: userRole,
              userLoginStatus: props.userLoginStatus,
              setUserData: props.setUserData
            });
        break;
    case "Certifications" :
    case "Media" :
        exit$1 = 1;
        break;
    
  }
  if (exit$1 === 1) {
    tmp$1 = JsxRuntime.jsx(ProviderShowContentCertifications.make, {
          provider: provider,
          certifications: provider.certifications,
          userRole: userRole,
          providerRole: providerRole,
          isMobile: isMobile
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                tmp,
                isMobile ? null : JsxRuntime.jsx(H1.make, {
                        id: "providerName",
                        className: css.providerName,
                        children: provider.name
                      }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(ProviderShowContentTabs.make, {
                            tabs: tabs,
                            currentTab: state.currentTab,
                            onClick: (function (tab) {
                                dispatch({
                                      TAG: "SelectTab",
                                      _0: tab
                                    });
                              })
                          }),
                      className: css.contentTabs,
                      id: "contentTabs"
                    }),
                tmp$1
              ],
              className: css.mainContentContainer
            });
}

var make = ProviderShowMainContent;

export {
  css ,
  initialState ,
  tabs ,
  make ,
}
/* css Not a pure module */
